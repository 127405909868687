body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#homepage-title {
  color: #3f51b5;
  text-align: center;
}

#react-paginate {
background-color: #3f51b5;
width: 90%;
margin: 0 auto;
}

#react-paginate ul {
display: inline-block;
padding-left: 0px;
padding-right: 0px;
}

#react-paginate li {
display: inline-block;
padding-left: 10px;
padding-right: 10px;
font-weight: normal;
color: #ffffff;
}

.homepage-loader {
  width: 100%;
  height: 30px;
  margin-top: 30px;
}

.homepage-loader div {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-paginate-active {
  font-weight: bold;
  text-decoration: underline;
  color: #ffffff;
}

.homepage_table {
  width: 90% !important;
  margin: 0 auto;
  max-height: calc(100vh - 220px);
}

.homepage_table thead {
  height: 60px;
}

.homepage_table thead tr th {
  font-weight: bold;
}

.withdrawal_table .MuiTable-root {
  min-width: 1115px;
}

.consultants_table .MuiTable-root {
  min-width: 1640px;
}

.client_table .MuiTable-root {
  min-width: 1420px;
}

.corporate_table .MuiTable-root {
  min-width: 1060px;
}

.client_table_big .MuiTable-root {
  min-width: 1910px;
}

.credits_table .MuiTable-root {
  min-width: 800px;
}

.credits_table .color_red {
  color: red;
}

.generate_button {
  text-align: center;
  margin-bottom: 15px;
}

.generate_coupons_dialog {
  display: flex;
  align-items: center;
}

.generate_coupons_dialog .MuiFormControl-root {
  width: 70px;
  margin-left: 10px;
}

.delete_button {
  color: red !important;
  height: 30px;
}

.string_column {
  min-width: 200px;
}

.numerar_column {
  min-width: 100px;
}

.kind_column {
  min-width: 80px;
}

.date_column {
  min-width: 160px;
}

.destination_column {
  min-width: 155px;
}

.actions_column {
  min-width: 140px;
}

.table_pagination {
  width: 90%;
  margin: 0 auto;
}

.corporate_line th, .corporate_line td {
  width: 15% !important;
}

.drawer .MuiDrawer-paperAnchorRight {
  width: 250px;
}

.drawer .MuiDrawer-paperAnchorRight ul {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon_input {
  padding-right: 0 !important;
}

.MuiTabs-fixed > span {
  display: none;
}
