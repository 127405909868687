.modal {
  font-size: 12px;
}

.modal .header {
  width: 100%;
  font-size: 25px;
  text-align: center;
  padding: 5px;
  padding-bottom: 10px;
}

.modal .form {
  display: flex;
  flex-direction: column;
}

.modal .form .actions {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.modal .form .actions * {
  margin: 0 10px;
}
