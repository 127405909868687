#add-corporate {
    margin-left: 5%;
    margin-bottom: 30px;
}

#form-add {
    margin: 0 5%;
    display: flex;
    flex-direction: column;
}

#form-add #upload_image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

#form-add #form-item {
    display: flex;
    margin: 5px 0;
}

#form-add #form-item *:first-child {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
}

#form-add #form-item .MuiSelect-select.MuiSelect-select, .form-select-categories {
    width: 400px;
}

.selected_category {
    background-color: rgb(164, 164, 255);
}

#form-input {
    width: 200px;
}

#form-about-me {
    width: 40%;
    height: 100px;
}

#zoom-slider, #form-add #form-item #zoom-slider .MuiSlider-rail {
    width: 200px;
}

#form-submit {
    width: 100%;
    text-align: center;
    margin: 30px 0;
}

#form-submit input {
    width: 200px;
}
